import Vue from 'vue'

Vue.filter('pluralize', (word: string, amount: number) => (amount > 1 || amount === 0 ? `${word}s` : word))
Vue.filter('limit', (value: string, size: number): string => {
  if (!value) return ''

  value = value.toString()

  if (value.length <= size) return value

  return value.substr(0, size) + '...'
})
