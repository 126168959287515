import Vue from 'vue'
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
import VuetifyToast from 'vuetify-toast-snackbar-ng'

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
})

const vuetifyObj = new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#e61a43',
        accent: '#0062ff',
      },
    },
  },
})

Vue.use(VuetifyToast, {
  x: 'center',
  showClose: true,
  closeText: 'close',
  shorts: {
    success: {
      color: 'green',
      icon: 'mdi-check',
    },
    successNoIcon: {
      color: 'green',
    },
    warning: {
      color: 'orange',
      icon: 'mdi-alert',
    },
    error: {
      color: 'red',
      icon: 'mdi-alert-circle-outline',
    },
  },
})

export default vuetifyObj
