type OfferCompleteModal = {
  proofType: string
  businessBrand: string
  offerSummary: string
  offerShortUrlId: string
  completedOffersCount: number
}

type OfferCompleteModalState = {
  modalData: OfferCompleteModal
}

const state: OfferCompleteModalState = {
  modalData: {
    proofType: '',
    businessBrand: '',
    offerSummary: '',
    offerShortUrlId: '',
    completedOffersCount: 0,
  },
}

const mutations = {
  modalData(state: OfferCompleteModalState, modalData: OfferCompleteModal) {
    state.modalData = modalData
  },
}

const actions = {
  setCompletedOfferModalData(
    { commit },
    {
      proofType,
      businessBrand,
      offerSummary,
      offerShortUrlId,
      completedOfferCount,
    }: { proofType: string; businessBrand: string; offerSummary: string; offerShortUrlId: string; completedOfferCount: number }
  ) {
    commit('modalData', { proofType, businessBrand, offerSummary, offerShortUrlId, completedOfferCount })
  },
}

const getters = {
  completedOfferModalData: () => state.modalData,
}

export const offerCompletedModal = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
