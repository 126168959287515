import Vue from 'vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'

Vue.filter('shortDate', (dateValue: Date) => {
  dayjs.extend(advancedFormat)
  return dayjs(dateValue).format('MMM Do')
})

Vue.filter('redeemedDate', (dateValue: Date) => {
  return dayjs(dateValue).format('MMM D, YYYY [at] h:mma')
})

Vue.filter('redeemedDateShort', (dateValue: Date) => {
  return dayjs(dateValue).format('MMM D, YYYY h:mma')
})

Vue.filter('slashDate', (dateValue: Date) => {
  return dayjs(dateValue).format('MM/DD/YY')
})

Vue.filter('timeUntil', (dateValue: Date) => {
  dayjs.extend(relativeTime)

  return dayjs().to(dateValue, true)
})

Vue.filter('roundedFull', (num: number) => {
  if (num < 0.5) {
    return '< 0.5'
  }
  return Math.round(num)
})
