import Vue from 'vue'
import Vuex from 'vuex'

import { app, auth, reservations, offers, offerCompletedModal } from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { app, auth, reservations, offers, offerCompletedModal },
})
