import Vue from 'vue'
import { ApiService } from '../../services'

interface Offer {
  offer_id: string
}

interface OfferState {
  offers: Offer[]
  offset: number
  scrollPosition: number
  loading: boolean
  shouldLoadMore: boolean
  searchLatitude: number | null
  searchLongitude: number | null
  offerListUpdateTimeout: number | null
  showUpdateOfferListAlert: boolean
}

const HEADER_OFFSET = 65
const OFFER_REQUEST_LIMIT = 10
const OFFER_UPDATE_ALERT_TIMEOUT = 600000

const state: OfferState = {
  offers: [],
  offset: 0,
  scrollPosition: 0,
  loading: true,
  shouldLoadMore: true,
  searchLatitude: null,
  searchLongitude: null,
  offerListUpdateTimeout: null,
  showUpdateOfferListAlert: false,
}

const getters = {
  loading: () => state.loading,
  offerList: () => state.offers,
  searchLatitude: () => state.searchLatitude,
  searchLongitude: () => state.searchLongitude,
  scrollPosition: () => state.scrollPosition,
  shouldLoadMore: () => state.shouldLoadMore,
  showUpdateOfferListAlert: () => state.showUpdateOfferListAlert,
  getOffer: (state: OfferState) => (offerId: string) => {
    return state.offers.find((offer) => offer.offer_id === offerId)
  },
}

const actions = {
  setSearchLatitude({ commit }, latitude: number | null) {
    commit('searchLatitude', latitude)
  },
  setSearchLongitude({ commit }, longitude: number | null) {
    commit('searchLongitude', longitude)
  },
  setScrollPosition({ commit }, scrollPosition: number) {
    commit('scrollPosition', scrollPosition)
  },
  resetOfferList({ commit }) {
    commit('resetOfferList')
  },
  fetchOffers({ commit }, resetList: boolean = false) {
    commit('loading', true)
    commit('setupOfferListUpdateAlert')

    if (resetList) {
      commit('resetOfferList')
    }

    const url = state.searchLatitude && state.searchLongitude ? `deal/${state.searchLatitude}/${state.searchLongitude}` : 'deal/web'

    ApiService.get(`${url}?offset=${state.offset}&limit=${OFFER_REQUEST_LIMIT}`)
      .then((response) => {
        if (response.data.error) throw new Error(response.data.error)

        commit('addOffersToList', response.data)
      })
      .catch((error) => {
        commit('loading', false)

        Vue.prototype.$toast.error(error)
      })
  },
  sendOfferViewedAuditEvent({}, offerIds: string[]) {
    ApiService.post('/offerAudit/viewed', { offerIds })
  },
}

const mutations = {
  loading(state: OfferState, loading: boolean) {
    state.loading = loading
  },
  searchLatitude(state: OfferState, latitude: number | null) {
    state.searchLatitude = latitude
  },
  searchLongitude(state: OfferState, longitude: number | null) {
    state.searchLongitude = longitude
  },
  scrollPosition(state: OfferState, scrollPosition: number) {
    state.scrollPosition = scrollPosition - HEADER_OFFSET
  },
  addOffersToList(state: OfferState, newOffers: Offer[]) {
    state.offers = [...state.offers].concat(newOffers)
    state.offset = state.offset + newOffers.length
    state.shouldLoadMore = newOffers.length === OFFER_REQUEST_LIMIT
    state.loading = false
  },
  resetOfferList(state: OfferState) {
    state.offers = []
    state.offset = 0
    state.shouldLoadMore = true
    state.showUpdateOfferListAlert = false
  },
  setupOfferListUpdateAlert(state: OfferState) {
    window.clearTimeout(state.offerListUpdateTimeout)
    state.offerListUpdateTimeout = setTimeout(() => {
      state.showUpdateOfferListAlert = true
    }, OFFER_UPDATE_ALERT_TIMEOUT)
  },
}

export const offers = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
