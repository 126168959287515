import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import { TokenService } from '@/services'

import store from '@/store'

Vue.use(VueRouter)

function loadView(view: string) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}

const backwardsCompatibleRoutes: Array<RouteConfig> = [
  {
    path: '/voucher/(\\d{4}-\\d{2}-\\d{2})/*',
    redirect: '/user/offers/incomplete',
  },
  {
    path: '/swayOffer/claim/:swayOfferId/:addressId?',
    beforeEnter(to, from, next) {
      const { swayOfferId, addressId } = to.params
      window.location.replace(`https://offers.getsway.com/offer/${swayOfferId}${addressId ? `/${addressId}` : ''}`)
    },
  },
  {
    path: '/swayOffer/(redeem|entered)/:swayOfferProofId',
    beforeEnter(to, from, next) {
      window.location.replace(`https://offers.getsway.com/redeemed/${to.params.swayOfferProofId}`)
    },
  },
]

const routes: Array<RouteConfig> = [
  {
    path: '/claimOffer/:offerId/:addressId?/:fromLong?/:fromLat?',
    alias: ['/claimOffer/:offerId', '/offer/:offerId/*'],
    name: 'ClaimOffer',
    meta: { hideBottomNav: true },
    props: (route) => ({ offerId: route.params.offerId, addressId: route.params.addressId, fromLong: route.params.fromLong, fromLat: route.params.fromLat }),
    component: loadView('offers/ClaimOffer'),
  },
  {
    path: '/voucher/:voucherId',
    name: 'Voucher',
    meta: { requiresAuth: true, hideBottomNav: true },
    component: loadView('offers/Voucher'),
  },
  {
    path: '/coupon/:voucherId',
    name: 'Coupon',
    meta: { requiresAuth: true, hideBottomNav: true },
    component: loadView('offers/Coupon'),
  },
  {
    path: '/signUp',
    name: 'Signup',
    meta: { redirectIfAuthed: '/' },
    component: loadView('user/Signup'),
  },
  {
    path: '/proof/:taskType/:voucherId',
    name: 'ProofPhotoUpload',
    meta: { requiresAuth: true, hideBottomNav: true },
    component: loadView('proofs/ProofPhotoUpload'),
  },
  {
    path: '/auth/login/:token',
    name: 'TokenLogin',
    component: loadView('auth/TokenLogin'),
  },
  {
    path: '/auth/verify',
    name: 'VerifyEmail',
    component: loadView('auth/Verify'),
  },
  {
    path: '/user/offers/:category?',
    name: 'MyOffers',
    meta: { requiresAuth: true },
    component: loadView('user/MyOffers'),
  },
  {
    path: '/user/profile',
    meta: { requiresAuth: true },
    component: loadView('user/Profile'),
    children: [
      {
        name: 'Personal',
        path: '',
        component: loadView('user/Profile/Personal'),
        meta: { requiresAuth: true },
      },
      {
        name: 'Social',
        path: '/user/social',
        component: loadView('user/Profile/Social'),
        meta: { requiresAuth: true },
      },
      {
        name: 'Communication',
        path: '/user/communication',
        component: loadView('user/Profile/Communication'),
        meta: { requiresAuth: true },
      },
      {
        name: 'Support',
        path: '/user/support',
        component: loadView('user/Profile/Support'),
      },
    ],
  },
  {
    path: '*',
    name: 'Offers',
    component: loadView('offers/Offers'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...backwardsCompatibleRoutes, ...routes],
})

router.beforeEach((to, from, next) => {
  if (store.getters['auth/isLoginModalVisible']) {
    store.dispatch('auth/hideLoginModal')
    next(false)

    return
  }

  if (to.matched.some((record) => record.meta.redirectIfAuthed) && TokenService.getToken()) {
    next({
      path: to.meta.redirectIfAuthed,
    })
    return
  }

  if (to.matched.some((record) => record.meta.requiresAuth) && !TokenService.getToken()) {
    store.dispatch('auth/showLoginModal')
    next(false)

    return
  }

  next()
})

export default router
