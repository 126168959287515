<template>
  <div>
    <FacebookReauthModal v-if="influencerNeedsEmailPermission" permission="email address" @start-fb-reauth="handleFbReauthRequest" />

    <v-dialog
      max-width="450"
      content-class="login-modal"
      data-cy="loginModal"
      :value="value"
      @input="emitCloseModalEvent()"
      @keydown.esc="emitCloseModalEvent()"
      @click:outside="emitCloseModalEvent()"
    >
      <v-card>
        <v-img class="white--text mb-6" height="300px" :src="require('@/assets/login-banner.png')">
          <v-card-title class="login-modal-image-text font-weight-bold">
            A world of offers. Just one click.
            <v-img :src="require('@/assets/logo.png')" contain height="18" class="mt-12 ml-n8" />
          </v-card-title>
        </v-img>

        <v-card-text v-if="loading">
          <app-spinner />
        </v-card-text>

        <v-card-text v-else-if="!showCheckEmail" class="text--primary">
          <h2 class="text-center font-weight-light text--secondary">Enter your email to get this offer.</h2>
          <p class="text--secondary text-center caption mt-2">Enter your email and we'll send a magic link to access offers.</p>

          <v-form ref="loginForm" v-model="valid">
            <v-text-field
              ref="emailField"
              v-model="email"
              :rules="emailRules"
              :validate-on-blur="true"
              placeholder="Type your e-mail here"
              required
              data-cy="email"
              @keypress.enter.prevent="doEmailLogin"
            />

            <v-btn large color="primary" block rounded class="mt-1" data-cy="signInButton" @click="doEmailLogin">Continue with email</v-btn>
          </v-form>

          <div class="text-center font-weight-light mt-3 mb-3">or</div>

          <v-btn large color="accent" block rounded :disabled="!facebookInitialized" @click="doFbLogin(false)">CONTINUE WITH FACEBOOK</v-btn>
        </v-card-text>

        <v-card-text v-else-if="showAuthByPin" class="text-center">
          <h2 class="font-weight-light text--secondary">Awesome!</h2>
          <p class="mt-6">Enter the PIN from your magic link email</p>
          <v-row>
            <v-col cols="8">
              <v-text-field v-model="authPin" label="PIN" dense outlined @keypress.enter.prevent="doPinLogin" />
            </v-col>
            <v-col cols="4" class="align-center fill-height">
              <v-btn color="primary" block rounded @click.prevent="doPinLogin">Login</v-btn>
            </v-col>
          </v-row>
          <p>
            Didn't get an email?
            <br />
            <a href data-cy="resetLoginEmailLink" @click.prevent="doEmailLogin">Click here to resend it</a>
          </p>
        </v-card-text>

        <v-card-text v-else class="text-center">
          <h2 class="font-weight-light text--secondary">You're almost there!</h2>
          <p class="mt-4">Check your email for a magic link to access offers</p>
          <p>
            Want to stay logged in? Enter your PIN
            <br />
            <a href @click.prevent="showAuthByPin = true">Click here to use a PIN</a>
          </p>
          <p>
            Didn't get an email?
            <br />
            <a href data-cy="resetLoginEmailLink" @click.prevent="doEmailLogin">Click here to resend it</a>
          </p>
          <div class="text-center font-weight-light mt-3 mb-3">or</div>
          <v-btn large color="accent" block rounded :disabled="!facebookInitialized" @click="doFbLogin(false)">CONTINUE WITH FACEBOOK</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'

import validationRules from '@/utils/validation'
import { ApiService, FacebookService } from '@/services'

import FacebookReauthModal from '@/components/FacebookReauthModal.vue'

export default Vue.extend({
  name: 'LoginModal',

  components: {
    FacebookReauthModal,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    valid: false,
    email: '',
    authPin: '',
    userId: '',
    loading: false,
    showAuthByPin: false,
    showCheckEmail: false,
    facebookInitialized: false,
    influencerNeedsEmailPermission: false,
    emailRules: validationRules.emailRules,
  }),

  mounted: function () {
    FacebookService.init().then(() => {
      this.facebookInitialized = true
    })
  },

  methods: {
    ...mapActions('auth', ['login']),
    doEmailLogin: function () {
      if (this.$refs.loginForm && !this.$refs.loginForm.validate()) {
        this.$refs.emailField.blur()
        return
      }

      this.loading = true

      ApiService.post(`auth/email`, {
        email: this.email,
        redirect: this.$router.currentRoute.path,
      })
        .then((response) => {
          this.loading = false
          this.showCheckEmail = true
          this.userId = response.data.userId
        })
        .catch(() => {
          this.loading = false
          this.$toast.error('Unable to login. Please try again later.')
        })
    },
    doPinLogin: function () {
      this.loading = true

      ApiService.post(`auth/pin`, {
        authPin: this.authPin,
        userId: this.userId,
      })
        .then((response) => {
          this.loading = false

          if (response.data.error) {
            this.$toast.error(response.data.error)
            return
          }

          this.login({ token: response.data.token })
          this.$toast.success('Success! Your now logged in.')
          this.emitCloseModalEvent()
        })
        .catch(() => {
          this.loading = false
          this.$toast.error('Unable to login. Please try again later.')
        })
    },
    handleFbReauthRequest(value) {
      this.influencerNeedsEmailPermission = false

      if (!value) return

      this.doFbLogin(true)
    },
    doFbLogin: async function (rerequest = false) {
      this.loading = true

      const fbLoginResponse = await FacebookService.login('email', rerequest)
      if (!fbLoginResponse.authResponse) {
        this.loading = false
        return
      }

      if (!fbLoginResponse.authResponse.grantedScopes.includes('email')) {
        this.loading = false
        this.influencerNeedsEmailPermission = true
        return
      }

      ApiService.post(`auth/facebook`, {
        accessToken: fbLoginResponse.authResponse.accessToken,
      })
        .then((response) => {
          this.login({ token: response.data.token })
          this.$toast.success('Success! Your now logged in.')
          this.emitCloseModalEvent()
        })
        .catch(() => {
          this.loading = false
          this.$toast.error('Unable to login. Please try again later.')
        })
    },
    emitCloseModalEvent() {
      this.$emit('login-modal-close')
    },
  },
})
</script>

<style>
.login-modal {
  border-radius: 25px;
}

.login-modal-image-text {
  width: 220px;
  font-size: 42px;
  line-height: 1.2;
  padding-top: 20px;
  padding-left: 20px;
  word-break: break-word;
}
</style>
