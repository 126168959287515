import axios, { AxiosRequestConfig } from 'axios'
import { TokenService, UserService } from '.'
import store from '../store'
import Vue from 'vue'

const appUpdateInterceptor = axios.interceptors.response.use(
  function (response) {
    const currentVersion = response.headers['local-version'] || ''
    const currentVersionChunks = currentVersion.split('.')
    const localVersionChunks = store.getters['app/appVersion'].split('.')

    if (localVersionChunks.length !== currentVersionChunks.length) return response

    for (let i = 0; i < localVersionChunks.length; i++) {
      if (parseInt(localVersionChunks[i]) < parseInt(currentVersionChunks[i])) {
        store.dispatch('app/setAppOutOfDate', true)
        return response
      }
    }

    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

export const ApiService = {
  init(baseURL: string) {
    axios.defaults.baseURL = baseURL
    axios.defaults.headers.common['app-type'] = 'Local'
    axios.defaults.headers.common['app-version'] = store.getters['app/appVersion']
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error.response.status === 401) {
          const loggedIn = store.getters['auth/loggedIn']
          if (loggedIn) {
            Vue.prototype.$toast.info('Your session has expired please try again.')
            store.dispatch('auth/logout')
          }
        }
      }
    )
  },

  setAuthHeader() {
    axios.defaults.headers.common['Authorization'] = `Bearer ${TokenService.getToken()}`
  },

  removeAuthHeader() {
    delete axios.defaults.headers.common['Authorization']
  },

  get(resource: string, config?: AxiosRequestConfig) {
    return axios.get(resource, config).then((res) => {
      if (!res) {
        return null
      }
      if (res.data.newToken) {
        UserService.login(res.data.newToken)
        return axios.get(resource, config)
      }
      return res
    })
  },

  post(resource: string, data: any) {
    return axios.post(resource, data).then((res) => {
      if (!res) {
        return null
      }
      if (res.data.newToken) {
        UserService.login(res.data.newToken)
        return axios.post(resource, data)
      }
      return res
    })
  },

  put(resource: string, data: any) {
    return axios.put(resource, data).then((res) => {
      if (!res) {
        return null
      }
      if (res.data.newToken) {
        UserService.login(res.data.newToken)
        return axios.put(resource, data)
      }
      return res
    })
  },

  delete(resource: string, config?: AxiosRequestConfig) {
    return axios.delete(resource, config).then((res) => {
      if (!res) {
        return null
      }
      if (res.data.newToken) {
        UserService.login(res.data.newToken)
        return axios.delete(resource, config)
      }
      return res
    })
  },

  customRequest() {
    const instance = axios.create()
    instance.defaults.headers.common = {}
    instance.interceptors.response.eject(appUpdateInterceptor)

    return instance
  },
}
