import router from '@/router'

import { ApiService, UserService, Constants, Storage } from '../../services'

interface AuthState {
  accessToken: null | string
  authenticationError: string
  loginModalVisible: boolean
  user: {}
}

enum NotificationChoice {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

const state: AuthState = {
  authenticationError: '',
  loginModalVisible: false,
  accessToken: localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_KEY),
  user: {
    email: null,
    first_name: null,
    last_name: null,
    phone: null,
    instagram_handle: null,
    survey_notification_email: false,
    survey_notification_sms: false,
    need_proof_notification_choice: NotificationChoice,
    expiring_notification_choice: NotificationChoice,
  },
}

const getters = {
  loggedIn: (state: AuthState) => {
    return !!state.accessToken
  },

  authenticationError: (state: AuthState) => {
    return state.authenticationError
  },

  isLoginModalVisible: (state: AuthState) => {
    return state.loginModalVisible
  },

  user: () => state.user,
}

const actions = {
  async login({ commit, dispatch }, { token, redirect }: { token: string; redirect: string | null }) {
    commit('loginRequest')

    await UserService.login(token)

    commit('loginSuccess', token)

    dispatch('reservations/fetchReservationSummary', null, { root: true })
    dispatch('getUser')

    if (redirect) {
      router.push({ path: redirect.replace(/\/\/$/, '/') })
    }
  },

  async logout({ commit }) {
    await UserService.logout()

    commit('logoutSuccess')

    Storage.removeItem(Constants.OFFER_FILTER_ADDRESS)

    if (router.currentRoute.path !== '/') {
      router.push({ path: '/' })
    }
  },

  getUser({ commit }) {
    ApiService.get('user/loggedIn').then((response) => {
      commit('user', response.data)
    })
  },

  showLoginModal({ commit }) {
    commit('loginModalVisible', true)
  },

  hideLoginModal({ commit }) {
    commit('loginModalVisible', false)
  },
}

const mutations = {
  loginRequest(state: AuthState) {
    state.authenticationError = ''
  },

  loginSuccess(state: AuthState, accessToken: string) {
    state.accessToken = accessToken
  },

  loginError(state: AuthState, { errorMessage }: { errorMessage: string }) {
    state.authenticationError = errorMessage
  },

  logoutSuccess(state: AuthState) {
    state.accessToken = ''
  },

  loginModalVisible(state: AuthState, visible: boolean) {
    state.loginModalVisible = visible
  },

  user(state: AuthState, payload: {}) {
    state.user = payload
  },
}

export const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
