import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueObserveVisibility from 'vue-observe-visibility'

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { ApiService, TokenService } from './services'
import './filters'

Vue.use(VueMeta)
Vue.use(VueObserveVisibility)

Vue.mixin({
  methods: {
    navigateToVoucher: (voucherId: string) => {
      router.replace({ path: `/voucher/${voucherId || router.currentRoute.params.voucherId}` })
    },
  },
})

Vue.config.productionTip = false

Vue.component('AppSpinner', () => import('@/components/AppSpinner.vue'))

ApiService.init(process.env.VUE_APP_DIVOC_API_URL)

if (TokenService.getToken()) {
  ApiService.setAuthHeader()
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
