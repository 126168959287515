import { ApiService, TokenService } from '.'

enum NotificationChoice {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export const UserService = {
  login: function (token: string) {
    TokenService.saveToken(token)
    ApiService.setAuthHeader()

    return token
  },

  logout: async () => {
    TokenService.removeToken()
    ApiService.removeAuthHeader()
  },

  getEmail() {
    const decodedToken = TokenService.getDecodedToken()
    if (decodedToken.sub && decodedToken.sub.email) return decodedToken.sub.email

    return null
  },

  getUserId() {
    const decodedToken = TokenService.getDecodedToken()
    if (decodedToken.sub && decodedToken.sub.userId) return decodedToken.sub.userId

    return null
  },

  async setPersonalInfo(firstName: string, lastName: string, phone: string | null) {
    return await ApiService.put(`user/account/personal`, {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      phone: phone ? phone.replace(/[()\s-]/g, '') : null,
    })
  },

  async setEmail(email: string) {
    return await ApiService.put(`user/account/email`, {
      email,
    })
  },

  async setSocialTags(instagram: string) {
    return await ApiService.put(`user/account/social`, {
      instagram,
    })
  },

  async setCommunication(
    survey_notification_email: boolean,
    survey_notification_sms: boolean,
    need_proof_notification_choice: NotificationChoice,
    expiring_notification_choice: NotificationChoice
  ) {
    return await ApiService.put(`user/account/communication`, {
      survey_notification_email,
      survey_notification_sms,
      need_proof_notification_choice,
      expiring_notification_choice,
    })
  },
}
