const TOKEN_KEY = process.env.VUE_APP_AUTH_TOKEN_KEY

export const TokenService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY)
  },

  getDecodedToken() {
    try {
      return JSON.parse(atob(this.getToken().split('.')[1]))
    } catch (e) {
      return {}
    }
  },

  saveToken(accessToken: string) {
    localStorage.setItem(TOKEN_KEY, accessToken)
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY)
  },
}

export const Storage = {
  getItem(key: string) {
    return localStorage.getItem(key)
  },

  setItem(key: string, value: any) {
    return localStorage.setItem(key, value)
  },

  getOrSetDefault(key: string, fallback?: any) {
    let item: any = this.getItem(key)

    if (item === null) {
      item = fallback
      this.setItem(key, item)
    }

    return item
  },

  removeItem(key: string) {
    localStorage.removeItem(key)
  },
}
