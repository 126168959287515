declare global {
  interface Window {
    google: any
  }
}

export const GoogleMapService = {
  loadGoogleMapsSdk() {
    return new Promise((resolve) => {
      if (window.google) {
        resolve(window.google)
      }

      const googleMapsScriptId = 'google-maps-sdk'
      const firstScriptTag = document.querySelectorAll('script')[0]
      if (!firstScriptTag || !firstScriptTag.parentNode || document.getElementById(googleMapsScriptId)) {
        return
      }

      const googleMapsScript = document.createElement('script')
      googleMapsScript.id = googleMapsScriptId
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_APP_ID}&libraries=places`
      googleMapsScript.addEventListener('load', () => {
        resolve(window.google)
      })

      firstScriptTag.parentNode.insertBefore(googleMapsScript, firstScriptTag)
    })
  },

  doGeocode(searchCriteria: object) {
    return new Promise((resolve) => {
      new window.google.maps.Geocoder().geocode(searchCriteria, function (results, status) {
        if (status === 'OK') {
          resolve({
            address: results[0].formatted_address,
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
          })
        } else {
          resolve({
            error: status,
          })
        }
      })
    })
  },

  async init() {
    await this.loadGoogleMapsSdk()
  },

  async reverseGeocode(latLng: any) {
    return await this.doGeocode({ location: latLng })
  },

  async geocode(address: string) {
    return await this.doGeocode({ address: address })
  },

  buildMarker(latitude: number, longitude: number, customProperties: object) {
    const position = new window.google.maps.LatLng(latitude, longitude)

    return new window.google.maps.Marker({
      position,
      map: this.map,
      ...customProperties,
    })
  },
}
