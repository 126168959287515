<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list id="navBarMenuLinks" nav>
        <v-list-item v-for="(menuItem, index) in menuItems" :key="index" class="mt-5 mb-5 pt-2 pb-2">
          <v-icon class="mr-4">{{ menuItem.icon }}</v-icon>
          <a :href="menuItem.link" class="text--secondary" :target="menuItem.newTab ? '_blank' : '_self'">{{ menuItem.title }}</a>
        </v-list-item>
        <v-list-item v-if="!loggedIn" class="mt-5 mb-5 pt-2 pb-2" @click.stop="onLoginMenuClicked()">
          <v-icon class="mr-4">mdi-login</v-icon>
          <span class="text--secondary">Login</span>
        </v-list-item>

        <v-footer color="white" class="pb-8 text-center">
          <h4 class="version mb-sm-4 my-3 font-weight-light">Version {{ appVersion }}</h4>
          <v-btn v-if="loggedIn" color="primary" class="my-sm-4 mt-3 text-center ma-auto" primary @click.stop="onLogoutMenuClicked()">LOGOUT</v-btn>
        </v-footer>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar id="app-bar" app dense color="white" :flat="$router.currentRoute.path === '/'">
      <v-btn v-if="$router.currentRoute.path !== '/' && !$router.currentRoute.path.startsWith('/user/')" icon color="secondary" class="mr-5" @click="goBack()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-app-bar-nav-icon v-else color="text--secondary" class="hidden-md-and-up" @click="drawer = true" />

      <ul id="appBarMenuLinks" class="hidden-sm-and-down">
        <li v-for="(menuItem, index) in menuItems" :key="index">
          <a :href="menuItem.link" class="text--secondary mr-5" :target="menuItem.newTab ? '_blank' : '_self'">{{ menuItem.title }}</a>
        </li>
        <li v-if="!loggedIn" class="menu-link" @click.stop="onLoginMenuClicked()">
          <a href="#" class="text--secondary mr-5">Login</a>
        </li>
      </ul>
      <v-spacer></v-spacer>
      <router-link :to="toUrl()">
        <v-img class="right" max-width="170" :src="require('@/assets/svg/logo.svg')" />
      </router-link>
    </v-app-bar>

    <v-main class="mx-0">
      <LfAppEnvironmentBanner v-if="appEnvironment.bannerVisible" :environment="appEnvironment.environment" :version="appVersion" />
      <v-alert :value="appOutOfDate" dense class="ml-2 mr-2 mt-2 mb-n1" icon="mdi-update" type="info">
        <v-row dense>
          <v-col class="grow">
            <span class="font-weight-bold">Update available!</span>
          </v-col>
          <v-col class="shrink">
            <v-btn small outlined class="float-right" @click="reloadApp()">Update</v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <router-view />
    </v-main>

    <LoginModal :value="isLoginModalVisible" @login-modal-close="hideLoginModal()" />

    <CompletedOfferModal
      v-if="showOfferCompleteModal"
      :proof-type="completedOfferModalData.proofType"
      :business-brand="completedOfferModalData.businessBrand"
      :offer-short-url-id="completedOfferModalData.offerShortUrlId"
      :offer-summary="completedOfferModalData.offerSummary"
      :completed-offers-count="completedOfferModalData.completedOfferCount"
      @completed-offer-modal-close="showOfferCompleteModal = false"
    />

    <v-bottom-navigation v-if="showBottomNav()" :value="getActiveBottomButton()" app grow color="primary">
      <v-btn :to="toUrl()">
        <span>Offers</span>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn :to="reservationBadgeCount.myOffersUrl">
        <span>My Offers</span>
        <v-badge
          :value="reservationBadgeCount.count > 0"
          :color="reservationBadgeCount.color"
          :content="reservationBadgeCount.count"
          overlap
          offset-x="0"
          offset-y="12"
        >
          <v-icon>mdi-shopping</v-icon>
        </v-badge>
      </v-btn>

      <v-btn to="/user/profile">
        <span>Profile</span>
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import { UserService, Storage, Constants } from '@/services'

import LoginModal from '@/components/LoginModal.vue'
import CompletedOfferModal from '@/components/CompletedOfferModal'
import { LfAppEnvironmentBanner } from 'lf-common'

export default Vue.extend({
  name: 'App',

  metaInfo: {
    title: 'Localfluence',
    titleTemplate: '%s | Localfluence',
  },

  components: {
    LoginModal,
    CompletedOfferModal,
    LfAppEnvironmentBanner,
  },

  data: () => ({
    drawer: false,
    showOfferCompleteModal: false,
    menuItems: [
      { title: 'List your business', icon: 'mdi-domain', link: 'https://business.localfluence.com/', newTab: true },
      { title: 'FAQ', icon: 'mdi-help-circle-outline', link: 'https://localfluence.helpscoutdocs.com/', newTab: false },
    ],
  }),

  computed: {
    ...mapGetters('auth', ['user', 'loggedIn', 'isLoginModalVisible']),
    ...mapGetters('app', ['appOutOfDate', 'appProgressBarVisible', 'appEnvironment', 'appVersion']),
    ...mapGetters('offerCompletedModal', ['completedOfferModalData']),
    ...mapGetters('reservations', ['reservationBadgeCount']),
  },

  watch: {
    completedOfferModalData() {
      this.showOfferCompleteModal = true
    },
  },

  mounted() {
    if (this.loggedIn) {
      this.getUser()
      this.$store.dispatch('reservations/fetchReservationSummary')

      const interval = setInterval(() => {
        if (this.user.email) {
          clearInterval(interval)

          const tokenEmail = UserService.getEmail()

          if (tokenEmail && tokenEmail.match('@') && tokenEmail !== this.user.email) {
            UserService.setEmail(tokenEmail).finally(() => {
              this.getUser()
            })
          }
        }
      }, 200)
    }
  },

  methods: {
    ...mapActions('auth', ['getUser', 'showLoginModal', 'logout', 'hideLoginModal']),
    reloadApp() {
      location.reload()
    },
    toUrl() {
      const offerFilterAddress = Storage.getItem(Constants.OFFER_FILTER_ADDRESS)
      if (offerFilterAddress) {
        return `/?address=${encodeURIComponent(offerFilterAddress)}`
      }

      return '/'
    },
    goBack() {
      this.$router.go(-1)
    },
    onLoginMenuClicked() {
      this.drawer = false

      this.showLoginModal()
    },
    onLogoutMenuClicked() {
      this.drawer = false

      this.logout()
    },
    showBottomNav() {
      return this.loggedIn && !this.$router.currentRoute.meta.hideBottomNav
    },
    getActiveBottomButton() {
      if (this.$router.currentRoute.path === '/') return 0
      if (this.$router.currentRoute.path.startsWith('/user/offers')) return 1
      if (this.$router.currentRoute.path.startsWith('/user/')) return 2
    },
  },
})
</script>

<style lang="scss">
.break-word {
  word-break: break-word;
}

.v-btn:before {
  background-color: #fff;
}

.v-card__text,
.v-card__title {
  word-break: normal !important;
}

.v-card__title {
  text-align: center;
}

#navBarMenuLinks a {
  text-decoration: none;
}

#appBarMenuLinks {
  margin: 0;
  padding: 0;
  display: flex;
  list-style-type: none;
}

#appBarMenuLinks li {
  cursor: pointer;
}

#appBarMenuLinks a {
  text-decoration: none;
}

#appBarMenuLinks a:hover {
  font-weight: bold;
}

footer {
  > h4 {
    justify-content: center;
    order: 0;
    width: 100%;
  }

  > button {
    justify-content: center;
    order: 0;
  }
}
</style>
