import Vue from 'vue'

Vue.filter('manyNumberPretty', (numberValue: number) => {
  if (numberValue <= 0) {
    return '0'
  }
  if (numberValue >= 25 && numberValue <= 100) {
    return 'A Few'
  } else if (numberValue >= 100 && numberValue <= 1000) {
    return 'Some'
  } else if (numberValue > 1000) {
    return 'Many'
  } else return '' + numberValue
})
