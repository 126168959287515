// @ts-nocheck

import { ApiService } from '@/services'
import { ReservationCategory } from '@/enums/reservationCategory'

type ReservationSummary = {
  active: number
  pinned: number
  wait_list: number
}

type ReservationState = {
  reservationSummary: ReservationSummary
}

const state: ReservationState = {
  reservationSummary: {},
}

const mutations = {
  reservationSummary(state: ReservationState, payload: ReservationSummary) {
    state.reservationSummary = payload
  },
}

const actions = {
  async fetchReservationSummary({ commit }) {
    await ApiService.get('reservation/summary').then((response) => {
      commit('reservationSummary', response.data)
    })
  },
}

const getters = {
  reservationSummary: () => state.reservationSummary,
  reservationBadgeCount: () => {
    const { active, pinned, wait_list } = state.reservationSummary

    return {
      count: active > 0 ? active : pinned + wait_list,
      color: active > 0 ? 'primary' : 'grey lighten-1',
      myOffersUrl: active > 0 ? `/user/offers/${ReservationCategory.INCOMPLETE}` : `/user/offers/${ReservationCategory.CLAIMED}`,
    }
  },
}

export const reservations = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
