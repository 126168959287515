declare global {
  interface Window {
    FB: any
    fbAsyncInit: any
  }
}

interface FacebookLoginResponse {
  authResponse: {
    userID: string
    accessToken: string
    grantedScopes: string
  }
}

export const FacebookService = {
  loadFacebookSDK() {
    return new Promise((resolve) => {
      if (window.FB) {
        resolve(window.FB)
      }

      const facebookScriptId = 'facebook-sdk'
      const firstScriptTag = document.querySelectorAll('script')[0]
      if (!firstScriptTag || !firstScriptTag.parentNode || document.getElementById(facebookScriptId)) {
        return
      }

      const facebookScript = document.createElement('script')
      facebookScript.id = facebookScriptId
      facebookScript.src = '//connect.facebook.net/en_US/sdk.js'
      facebookScript.addEventListener('load', () => {
        resolve(window.FB)
      })

      firstScriptTag.parentNode.insertBefore(facebookScript, firstScriptTag)
    })
  },

  async init() {
    const FB: any = await this.loadFacebookSDK()

    return new Promise((resolve) => {
      FB.init({
        xfbml: false,
        version: 'v8.0',
        appId: process.env.VUE_APP_FACEBOOK_APP_ID,
      })

      resolve(FB)
    })
  },

  async login(scope: string, rerequest = false): Promise<FacebookLoginResponse> {
    const FB: any = await this.loadFacebookSDK()

    return new Promise((resolve) => {
      FB.login(
        (response: any) => {
          resolve(response)
        },
        {
          scope,
          return_scopes: true,
          ...(rerequest && { auth_type: 'rerequest' }),
        }
      )
    })
  },
}
